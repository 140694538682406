<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="client-modal-label">{{ form_title }} {{ clientId() }}</h5>
            <router-link
                tag="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                :to="{ name: 'clients' }"
                :disabled="registering_users"
            >
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </router-link>
        </div>

        <div v-if="loading">
            <div class="modal-body">
                <loading></loading>
            </div>
        </div>

        <div v-if="updated">
            <div class="modal-body">
                <div class="alert alert-success" role="alert">
                    <i class="far fa-check mr-2"></i>
                    <span v-html="updated"></span>
                </div>

                <loading v-if="registering_users"></loading>
            </div>
            <div class="modal-footer">
                <router-link
                    tag="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                    :to="{ name: 'clients' }"
                    :disabled="registering_users"
                >
                    <i class="fas fa-times mr2"></i> Close
                </router-link>
            </div>
        </div>

        <div v-if="(!loading && !updated)">
            <form v-on:submit.prevent="onSubmit()">
                <div class="modal-body" style="padding: 0">
                    <div
                        v-if="errors"
                        v-html="errors"
                        class="alert alert-danger"
                        role="alert"
                        style="margin: 1rem"
                    ></div>

                    <div v-if="!loading_errors" class="accordion" id="client-accordion">
                        <div class="card" style="border-radius: 0">
                            <h6
                                class="card-header"
                                id="client-heading-1"
                                data-toggle="collapse"
                                data-target="#client-collapse-1"
                                aria-expanded="true"
                                aria-controls="client-collapse-1"
                            >
                                <i class="far fa-file-alt mr5"></i> Basic details
                            </h6>
                            <div
                                id="client-collapse-1"
                                class="collapse show"
                                aria-labelledby="client-heading-1"
                                data-parent="#client-accordion"
                            >
                                <div class="card-body">
                                    <div v-if="!existing_client" class="alert alert-warning">
                                        Please ensure the DNS, folders and Apache config are complete
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="id" id="id-label" class="mandatory-label">Client ID</label>
                                                <the-mask
                                                    v-model="id"
                                                    type="text"
                                                    id="id"
                                                    class="form-control mandatory-field"
                                                    :readonly="existing_client || updating"
                                                    aria-describedby="id-help"
                                                    mask="ccccccccccccccccccccccccccccccccccccccccccccccccccccccccc"
                                                    :tokens="client_id_mask"
                                                ></the-mask>
                                                <small v-if="!existing_client" id="id-help" class="form-text text-muted">
                                                    Lowercase letters and numbers only
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="tenancy_db_name">Database name</label>
                                                <input
                                                    v-model="tenancy_db_name"
                                                    type="text"
                                                    id="tenancy_db_name"
                                                    class="form-control"
                                                    readonly
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="form-group">
                                                <label for="client_api_url">Client API URL</label>
                                                <input
                                                    v-model="client_api_url"
                                                    type="text"
                                                    id="client_api_url"
                                                    class="form-control"
                                                    readonly
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <label for="assets_disk">File storage</label>
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-block dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left; height: 37px"
                                                >
                                                    {{ (assets_disk == 'local') ? 'EBS (local filesystem)' : 'S3 (AWS)' }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        :style="'position: absolute; top: 35px; right: 25px; '
                                                            + 'font-size: 11px'"
                                                    ></i>
                                                </button>
                                                <div style="position: absolute">
                                                    <div class="dropdown-menu">
                                                        <a
                                                            v-for="option in disks"
                                                            class="dropdown-item"
                                                            :key="option.value"
                                                            href="#"
                                                            @click.prevent="assets_disk = option.value"
                                                        >{{ option.text }}</a>
                                                    </div>
                                                </div>
                                                <small v-if="(assets_disk == 's3')" class="form-text text-muted">
                                                    Folder: client_{{ id }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="client_name"
                                            id="client_name-label"
                                            class="mandatory-label"
                                        >
                                            Client name
                                        </label>
                                        <input
                                            v-model="client_name"
                                            type="text"
                                            id="client_name"
                                            class="form-control mandatory-field"
                                            :readonly="updating"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="overflow: visible; border-radius: 0">
                            <h6
                                class="card-header"
                                id="client-heading-2"
                                data-toggle="collapse"
                                data-target="#client-collapse-2"
                                aria-expanded="false"
                                aria-controls="client-collapse-2"
                            >
                                <i class="far fa-palette mr5"></i> Colours
                            </h6>
                            <div
                                id="client-collapse-2"
                                class="collapse"
                                aria-labelledby="client-heading-2"
                                data-parent="#client-accordion"
                            >
                                <div class="card-body">
                                    <p>MAIN COLOURS</p>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="primary_colour_name"
                                                    id="primary_colour_name-label"
                                                    class="mandatory-label"
                                                >Name</label>
                                                <input
                                                    v-model="primary_colour_name"
                                                    type="text"
                                                    id="primary_colour_name"
                                                    class="form-control mandatory-field"
                                                    placeholder="Give this colour a name"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="primary_colour_picker"
                                                    id="primary_colour-label"
                                                    class="mandatory-label"
                                                >Background colour</label>
                                                <colorpicker
                                                    :color="primary_colour"
                                                    v-model="primary_colour"
                                                    id="primary_colour_picker"
                                                    class="mandatory-field"
                                                ></colorpicker>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="primary_text_colour_picker"
                                                    id="primary_text_colour-label"
                                                    class="mandatory-label"
                                                >Text colour</label>
                                                <colorpicker
                                                    :color="primary_text_colour"
                                                    v-model="primary_text_colour"
                                                    id="primary_text_colour_picker"
                                                    class="mandatory-field"
                                                ></colorpicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="secondary_colour_name"
                                                    id="secondary_colour_name-label"
                                                    class="mandatory-label"
                                                >Name</label>
                                                <input
                                                    v-model="secondary_colour_name"
                                                    type="text"
                                                    id="secondary_colour_name"
                                                    class="form-control mandatory-field"
                                                    placeholder="Give this colour a name"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="secondary_colour_picker"
                                                    id="secondary_colour-label"
                                                    class="mandatory-label"
                                                >Background colour</label>
                                                <colorpicker
                                                    :color="secondary_colour"
                                                    v-model="secondary_colour"
                                                    id="secondary_colour_picker"
                                                    class="mandatory-field"
                                                ></colorpicker>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="secondary_text_colour_picker"
                                                    id="secondary_text_colour-label"
                                                    class="mandatory-label"
                                                >Text colour</label>
                                                <colorpicker
                                                    :color="secondary_text_colour"
                                                    v-model="secondary_text_colour"
                                                    id="secondary_text_colour_picker"
                                                    class="mandatory-field"
                                                ></colorpicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="third_colour_name"
                                                    id="third_colour_name-label"
                                                    class="mandatory-label"
                                                >Name</label>
                                                <input
                                                    v-model="third_colour_name"
                                                    type="text"
                                                    id="third_colour_name"
                                                    class="form-control mandatory-field"
                                                    placeholder="Give this colour a name"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="third_colour_picker"
                                                    id="third_colour-label"
                                                    class="mandatory-label"
                                                >Background colour</label>
                                                <colorpicker
                                                    :color="third_colour"
                                                    v-model="third_colour"
                                                    id="third_colour_picker"
                                                    class="mandatory-field"
                                                ></colorpicker>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label
                                                    for="third_text_colour_picker"
                                                    id="third_text_colour-label"
                                                    class="mandatory-label"
                                                >Text colour</label>
                                                <colorpicker
                                                    :color="third_text_colour"
                                                    v-model="third_text_colour"
                                                    id="third_text_colour_picker"
                                                    class="mandatory-field"
                                                ></colorpicker>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="mt-3">ADDITIONAL COLOURS</p>
                                    <client-additional-colours :data="id"></client-additional-colours>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="border-radius: 0">
                            <h6
                                class="card-header"
                                id="client-heading-3"
                                data-toggle="collapse"
                                data-target="#client-collapse-3"
                                aria-expanded="false"
                                aria-controls="client-collapse-3"
                            >
                                <i class="far fa-envelope mr5"></i> Timezone &amp; email settings
                            </h6>
                            <div
                                id="client-collapse-3"
                                class="collapse"
                                aria-labelledby="client-heading-3"
                                data-parent="#client-accordion"
                            >
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="timezone">Timezone</label>
                                                <input
                                                    v-model="timezone"
                                                    type="text"
                                                    id="timezone"
                                                    class="form-control"
                                                    :readonly="updating"
                                                    aria-describedby="timezone-help"
                                                >
                                                <small id="timezone-help" class="form-text text-muted">
                                                    e.g. Europe/London
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="support_email">Support email address</label>
                                                <input
                                                    v-model="support_email"
                                                    type="email"
                                                    id="support_email"
                                                    class="form-control"
                                                    :readonly="updating"
                                                    aria-describedby="support_email-help"
                                                >
                                                <small id="support_email-help" class="form-text text-muted">
                                                    Support requests will be sent here by default
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="mailgun_endpoint">Mailgun API endpoint</label>
                                                <input
                                                    v-model="mailgun_endpoint"
                                                    type="text"
                                                    id="mailgun_endpoint"
                                                    class="form-control"
                                                    :readonly="updating"
                                                    aria-describedby="mailgun_endpoint-help"
                                                >
                                                <small id="mailgun_endpoint-help" class="form-text text-muted">
                                                    e.g. api.mailgun.net
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="mailgun_domain">Mailgun domain</label>
                                                <input
                                                    v-model="mailgun_domain"
                                                    type="text"
                                                    id="mailgun_domain"
                                                    class="form-control"
                                                    :readonly="updating"
                                                    aria-describedby="mailgun_domain-help"
                                                >
                                                <small id="mailgun_domain-help" class="form-text text-muted">
                                                    e.g. resourcebaseintheclouds.com
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="mail_from_address">Email 'from' address</label>
                                                <input
                                                    v-model="mail_from_address"
                                                    type="email"
                                                    id="mail_from_address"
                                                    class="form-control"
                                                    :readonly="updating"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="mail_from_name">Email 'from' name</label>
                                                <input
                                                    v-model="mail_from_name"
                                                    type="text"
                                                    id="mail_from_name"
                                                    class="form-control"
                                                    :readonly="updating"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="border-radius: 0">
                            <h6
                                class="card-header"
                                id="client-heading-4"
                                data-toggle="collapse"
                                data-target="#client-collapse-4"
                                aria-expanded="false"
                                aria-controls="client-collapse-4"
                            >
                                <i class="far fa-user-lock mr5"></i> User roles &amp; permissions
                            </h6>
                            <div
                                id="client-collapse-4"
                                class="collapse"
                                aria-labelledby="client-heading-4"
                                data-parent="#client-accordion"
                            >
                                <div class="card-body">
                                    <client-roles
                                        :data="id"
                                        :auto_approve_role_original="auto_approve_role"
                                    ></client-roles>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!loading_errors" class="modal-footer" style="border-top: none">
                    <span v-if="!updating">
                        <span>
                            <button type="submit" class="btn btn-primary" :disabled="block_submit">
                                <i :class="button_icon"></i> {{ button_text }}
                            </button>
                        </span>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr2"></i>
                            <span style="opacity: 0.5">{{ button_text }}</span>
                        </button>
                    </span>
                    <router-link
                        tag="button"
                        class="btn btn-light"
                        data-dismiss="modal"
                        :disabled="updating"
                        :to="{ name: 'clients' }"
                    >
                        <i class="fas fa-times mr2"></i> Cancel
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                loading_errors: false,
                errors: false,
                form_title: 'Add client',
                button_icon: 'fas fa-plus mr2',
                button_text: 'Add',
                updating: false,
                updated: false,
                existing_client: false,
                id: '',
                tenancy_db_name: '',
                client_api_url: '',
                client_name: '',
                client_id_mask: {
                    c: {
                        pattern: /[0-9a-zA-Z]/,
                        transform: v => v.toLocaleLowerCase()
                    }
                },
                primary_colour_name: 'Colour 1',
                primary_colour: this.$root.$data.app.primary_colour,
                primary_text_colour: this.$root.$data.app.primary_text_colour,
                secondary_colour_name: 'Colour 2',
                secondary_colour: this.$root.$data.app.secondary_colour,
                secondary_text_colour: this.$root.$data.app.secondary_text_colour,
                third_colour_name: 'Colour 3',
                third_colour: this.$root.$data.app.third_colour,
                third_text_colour: this.$root.$data.app.third_text_colour,
                assets_disk: 'local',
                disks: [
                    {
                        value: 'local',
                        text: 'EBS (local filesystem)'
                    },
                    {
                        value: 's3',
                        text: 'S3 (AWS)'
                    }
                ],
                timezone: '',
                support_email: '',
                mailgun_domain: '',
                mailgun_endpoint: '',
                mail_from_address: '',
                mail_from_name: '',
                auto_approve_role: null,
                registering_users: false,
                initial_users: false,
                initial_users_registered: 0,
                admin_key: false,
                block_submit: false
            }
        },
        mounted () {
            //
        },
        watch: {
            id: function (val) {
                if (!this.existing_client) {
                    if (val != '') {
                        this.tenancy_db_name = 'rb_' + val;
                        this.client_api_url = this.$root.$data.app.api_url + '/' + val + '/...';
                    } else {
                        this.tenancy_db_name = '';
                        this.client_api_url = '';
                    }
                }
            },
            initial_users_registered: function (val) {
                if (val == this.initial_users.length) {
                    this.updated = "Client '" + this.id + "' created. " + val + " initial users registered.";
                    this.registering_users = false;
                    var vm = this;
                    setTimeout(function () {
                        $('#client-modal').modal('hide');
                        vm.$router.push({ name: 'clients' });
                    }, 2000);
                }
            }
        },
        methods: {
            editClient: function (id = '') {
                this.id = id;
                this.loading_errors = false;
                this.errors = false;
                this.updated = false;
                this.registering_users = false;
                this.$parent.$data.errors = false;
                this.loading = true;
                var vm = this;

                if (id == '') {
                    // new client - empty fields
                    this.existing_client = false;
                    this.form_title = 'Add client';
                    this.button_icon = 'fas fa-plus mr2';
                    this.button_text = 'Add';
                    this.tenancy_db_name = '';
                    this.client_api_url = '';
                    this.client_name = '';
                    this.primary_colour_name = 'Colour 1';
                    this.primary_colour = this.$root.$data.app.primary_colour;
                    this.primary_text_colour = this.$root.$data.app.primary_text_colour;
                    this.secondary_colour_name = 'Colour 2';
                    this.secondary_colour = this.$root.$data.app.secondary_colour;
                    this.secondary_text_colour = this.$root.$data.app.secondary_text_colour;
                    this.third_colour_name = 'Colour 3';
                    this.third_colour = this.$root.$data.app.third_colour;
                    this.third_text_colour = this.$root.$data.app.third_text_colour;
                    this.assets_disk = 'local';
                    this.timezone = '';
                    this.support_email = '';
                    this.mailgun_domain = '';
                    this.mailgun_endpoint = '';
                    this.mail_from_address = '';
                    this.mail_from_name = '';
                    this.auto_approve_role = null;
                    setTimeout(function () {
                        vm.loading = false;
                    }, 500);
                } else {
                    // existing client - get details and populate fields
                    this.existing_client = true;
                    this.form_title = 'Edit client';
                    this.button_icon = 'far fa-save mr2';
                    this.button_text = 'Save';
                    this.client_api_url = this.$root.$data.app.api_url + '/' + this.id + '/...';

                    /**
                     * Get client from API
                     */
                    const url = this.$root.$data.app.api_url + '/client/' + this.id;

                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                    })
                    .then(response => {
                        vm.tenancy_db_name = response.data.client.tenancy_db_name;
                        vm.client_name = response.data.client.client_name;
                        vm.primary_colour_name = response.data.client.primary_colour_name;
                        vm.primary_colour = response.data.client.primary_colour;
                        vm.primary_text_colour = response.data.client.primary_text_colour;
                        vm.secondary_colour_name = response.data.client.secondary_colour_name;
                        vm.secondary_colour = response.data.client.secondary_colour;
                        vm.secondary_text_colour = response.data.client.secondary_text_colour;
                        vm.third_colour_name = response.data.client.third_colour_name;
                        vm.third_colour = response.data.client.third_colour;
                        vm.third_text_colour = response.data.client.third_text_colour;
                        vm.timezone = response.data.client.timezone;
                        vm.support_email = response.data.client.support_email;
                        vm.mailgun_domain = response.data.client.mailgun_domain;
                        vm.mailgun_endpoint = response.data.client.mailgun_endpoint;
                        vm.mail_from_address = response.data.client.mail_from_address;
                        vm.mail_from_name = response.data.client.mail_from_name;
                        vm.auto_approve_role = response.data.client.auto_approve_role;
                        vm.assets_disk = response.data.client.assets_disk;
                    })
                    .catch(function (error) {
                        vm.loading_errors = true;
                        vm.errors = 'Something went wrong, please try again';
                        if (error.response) {
                            if (error.response.status == 401) {
                                // missing/lost API token - close modal and logout user
                                $('#client-modal').modal('hide');
                                vm.$root.doLogout();
                            }
                            if (error.response.data.error) {
                                vm.errors = error.response.data.error;
                            }
                        }
                    })
                    .finally(() =>
                        vm.loading = false
                    );
                }
            },
            setAutoApproveRole: function (role) {
                this.auto_approve_role = role;
            },
            onSubmit: function () {
                if (this.block_submit) {
                    return null;
                }
                this.updating = true;
                this.errors = false;

                /**
                 * Send request to API
                 */
                var path = '/client/' + this.id;
                var data = {
                    client_name: this.client_name,
                    primary_colour_name: this.primary_colour_name,
                    primary_colour: this.primary_colour,
                    primary_text_colour: this.primary_text_colour,
                    secondary_colour_name: this.secondary_colour_name,
                    secondary_colour: this.secondary_colour,
                    secondary_text_colour: this.secondary_text_colour,
                    third_colour_name: this.third_colour_name,
                    third_colour: this.third_colour,
                    third_text_colour: this.third_text_colour,
                    timezone: this.timezone,
                    support_email: this.support_email,
                    mailgun_domain: this.mailgun_domain,
                    mailgun_endpoint: this.mailgun_endpoint,
                    mail_from_address: this.mail_from_address,
                    mail_from_name: this.mail_from_name,
                    auto_approve_role: this.auto_approve_role,
                    assets_disk: this.assets_disk
                };
                var method = 'put';

                if (!this.existing_client) {
                    path = '/client';
                    data.id = this.id;
                    method = 'post';
                }

                const url = this.$root.$data.app.api_url + path;
                var vm = this;

                axios({
                    method: method,
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                    }

                    if (vm.existing_client) {
                        setTimeout(function () {
                            $('#client-modal').modal('hide');
                            vm.$parent.getClients();
                        }, 2000);

                        return null;
                    }
                    vm.initial_users_registered = 0;
                    vm.updated += '. Retrieving initial users to register. Please wait.';
                    vm.registering_users = true;
                    vm.getInitialUsers();
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#client-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                $('.mandatory-label').removeClass('text-danger');
                                $('.mandatory-field').removeClass('field-error');
                                $('.mandatory-field input').removeClass('field-error');
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml.replace(/primary colour name/g, 'main colour name')
                                        .replace(/primary colour/g, 'main background colour')
                                        .replace(/primary text colour/g, 'main text colour')
                                        .replace(/secondary colour name/g, 'main colour name')
                                        .replace(/secondary colour/g, 'main background colour')
                                        .replace(/secondary text colour/g, 'main text colour')
                                        .replace(/third colour name/g, 'main colour name')
                                        .replace(/third colour/g, 'main background colour')
                                        .replace(/third text colour/g, 'main text colour');
                                    $('#' + key + '-label').addClass('text-danger');
                                    $('#' + key).addClass('field-error');
                                    $('#' + key + '_picker input').addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            getInitialUsers: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/clients/initial-users';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.initial_users = response.data.users;
                    vm.getAdminKey();
                    if (vm.initial_users.length > 0) {
                        vm.updated = 'Number of initial users to register: ' + vm.initial_users.length
                        + '. Retrieving admin key. Please wait.';

                        return null;
                    }
                    vm.updated = "Client '" + vm.id + "' created. There are no initial users to register.";
                    vm.registering_users = false;
                })
                .catch(function (error) {
                    vm.updated = 'Could not retrieve initial users.';
                    if (error.response.data.error) {
                        vm.updated += ' ' + error.response.data.error;
                    }
                });
            },
            getAdminKey: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/admin-key';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.admin_key = response.data;
                    if (vm.initial_users.length > 0) {
                        vm.updated = 'Admin key received. Registering users. Please wait.';
                        vm.initial_users.forEach(function (item) {
                            vm.registerInitialUser(item);
                        });
                    }
                    vm.addDefaultContents();
                })
                .catch(function (error) {
                    vm.updated = 'Could not retrieve admin key';
                    if (error.response.data.error) {
                        vm.updated += '. ' + error.response.data.error;
                    }
                });
            },
            registerInitialUser: function (item) {
                /**
                 * Send request to API
                 */
                const path = '/' + this.id + '/user/register';
                var data = {
                    admin_key: this.admin_key,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    email: item.email,
                    password: item.password,
                    confirm_password: item.password
                };

                const url = this.$root.$data.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    vm.initial_users_registered += 1;
                })
                .catch(function (error) {
                    vm.updated = 'Client created but could not register all initial users';
                    vm.registering_users = false;
                });
            },
            clientId: function () {
                return (this.id == '') ? '' : "'" + this.id + "'";
            },
            addDefaultContents: function () {
                if (this.$root.app.default_terms_and_conditions) {
                    this.addClientContent('terms_and_conditions', this.$root.app.default_terms_and_conditions);
                }
            },
            addClientContent: function (key, content) {
                /**
                 * Send create setting request to API
                 */
                const path = '/' + this.id + '/content/admin/' + this.admin_key;
                const url = this.$root.app.api_url + path;
                const data = {
                    key: key,
                    content: content
                };

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + this.$root.token }
                })
                .then(response => {
                    // Do anything?
                })
                .catch(function (error) {
                    // Do anything?
                });
            }
        }
    }
</script>
