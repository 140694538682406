<template>
    <div>
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <h4 class="mb-0 font-size-18">Client list</h4>

                            <div class="page-title-right">
                                <breadcrumbs :items="breadcrumbs"></breadcrumbs>
                            </div>
                        </div>

                        <p class="mb-1">
                            <span class="mr-2" style="position: relative; top: -3px">Update API calls real time</span>
                            <span class="contact-links font-size-22">
                                <span
                                    v-if="!auto_update_api_calls"
                                    @click="auto_update_api_calls = 1"
                                >
                                    <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                </span>
                                <span v-else>
                                    <a
                                        href="#"
                                        @click.prevent="auto_update_api_calls = 0"
                                    >
                                        <i class="far fa-toggle-on"></i>
                                    </a>
                                </span>
                            </span>
                        </p>
                    </div>
                </div>

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-if="(!errors && !loading)">
                    <div v-if="num_items == 0" class="alert alert-warning" role="alert">
                        No results
                    </div>
                    <div v-if="num_items > 0" class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap table-hover">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">Client</th>
                                                    <th scope="col">Main colours</th>
                                                    <th scope="col">
                                                        <span class="d-none d-md-block">Storage in use</span>
                                                    </th>
                                                    <th scope="col">
                                                        <span class="d-none d-md-block">API calls MTD</span>
                                                    </th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="item in items"
                                                    :key="item.id"
                                                    @click="showClientForm(item.id)"
                                                    style="cursor: pointer"
                                                >
                                                    <td>
                                                        {{ item.client_name }}
                                                        <div v-if="item.url">
                                                            <a
                                                                :href="item.url"
                                                                target="_blank"
                                                                @click="block_show_client_form = true"
                                                            >
                                                                {{ item.url }}
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="item.primary_colour"
                                                            class="client-color"
                                                            :style="primaryColourStyles(item)"
                                                        >
                                                            <span
                                                                v-if="item.primary_colour_name.length > 12"
                                                                class="d-none d-md-inline"
                                                            >
                                                                {{ item.primary_colour_name.substring(0, 9) + '...' }}
                                                            </span>
                                                            <span v-else class="d-none d-md-inline">
                                                                {{ item.primary_colour_name }}
                                                            </span>
                                                            <span class="d-md-none">
                                                                {{ item.primary_colour_name.substring(0, 1) }}
                                                            </span>
                                                        </span>
                                                        <span
                                                            v-if="item.secondary_colour"
                                                            class="client-color"
                                                            :style="secondaryColourStyles(item)"
                                                        >
                                                            <span
                                                                v-if="item.secondary_colour_name.length > 12"
                                                                class="d-none d-md-inline"
                                                            >
                                                                {{ item.secondary_colour_name.substring(0, 9) + '...' }}
                                                            </span>
                                                            <span v-else class="d-none d-md-inline">
                                                                {{ item.secondary_colour_name }}
                                                            </span>
                                                            <span class="d-md-none">
                                                                {{ item.secondary_colour_name.substring(0, 1) }}
                                                            </span>
                                                        </span>
                                                        <span
                                                            v-if="item.third_colour"
                                                            class="client-color"
                                                            :style="thirdColourStyles(item)"
                                                        >
                                                            <span
                                                                v-if="item.third_colour_name.length > 12"
                                                                class="d-none d-md-inline"
                                                            >
                                                                {{ item.third_colour_name.substring(0, 9) + '...' }}
                                                            </span>
                                                            <span v-else class="d-none d-md-inline">
                                                                {{ item.third_colour_name }}
                                                            </span>
                                                            <span class="d-md-none">
                                                                {{ item.third_colour_name.substring(0, 1) }}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span style="display: inline-block; width: 30px">
                                                            {{ (item.assets_disk == 'local') ? 'EBS' : 'S3' }}
                                                        </span>
                                                        {{ formatStorageSize(item.assets_disk_size) }}
                                                    </td>
                                                    <td :key="item.id + '_ac_' + get_api_calls_count">
                                                        <span
                                                            v-if="apiCalls(item.id) === null"
                                                            class="d-none d-md-block"
                                                        >
                                                            <loading size="1" align="left"></loading>
                                                        </span>
                                                        <span v-else class="d-none d-md-block">
                                                            {{ apiCalls(item.id) }}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <ul class="list-inline font-size-18 contact-links mb-0">
                                                            <li class="list-inline-item">
                                                                <a
                                                                    v-b-tooltip.hover
                                                                    title="Edit"
                                                                    @click.prevent="showClientForm(item.id)"
                                                                >
                                                                    <i class="far fa-edit"></i>
                                                                </a>
                                                            </li>
                                                            <li class="list-inline-item">
                                                                <router-link
                                                                    v-b-tooltip.hover
                                                                    title="Settings"
                                                                    :to="{ name: 'client-settings', params: { client_id: item.id } }"
                                                                    v-on:click.native="block_show_client_form = true"
                                                                >
                                                                    <i class="far fa-cog"></i>
                                                                </router-link>
                                                            </li>
                                                            <li class="list-inline-item">
                                                                <router-link
                                                                    v-b-tooltip.hover
                                                                    title="API calls"
                                                                    :to="{ name: 'client-api-calls', params: { client_id: item.id } }"
                                                                    v-on:click.native="block_show_client_form = true"
                                                                >
                                                                    <i class="far fa-right-left"></i>
                                                                </router-link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <site-footer></site-footer>

        <div
            class="modal fade"
            id="client-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="client-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg">
                <client-form></client-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['add'],
        data () {
            return {
                breadcrumbs: [
                    {
                        id: 0,
                        text: "Clients",
                        active: true,
                        link: 'clients'
                    },
                    {
                        id: 1,
                        text: "Client list"
                    }
                ],
                loading: false,
                errors: false,
                items: [],
                num_items: 0,
                block_show_client_form: false,
                admin_key: null,
                total_api_calls: {},
                get_api_calls_count: 0,
                get_api_calls_interval: null,
                auto_update_api_calls: 0
            }
        },
        mounted () {
            const cookieVal = parseInt(this.$cookie.get('resourcebase_admin_auac'));
            if (!isNaN(cookieVal)) {
                this.auto_update_api_calls = cookieVal;
            }
            this.getClients();
            if (this.add) {
                this.showClientForm();
            }

            this.$root.expandSideNav();

            var vm = this;
            this.get_api_calls_interval = setInterval(function () {
                vm.getApiCalls();
            }, 15000);
        },
        beforeDestroy() {
            clearInterval(this.get_api_calls_interval);
        },
        watch: {
            block_show_client_form: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.block_show_client_form = false;
                    }, 200);
                }
            },
            auto_update_api_calls: function (val) {
                this.$root.setCookie('resourcebase_admin_auac', val, '1Y');
            }
        },
        methods: {
            showClientForm: function (id = '') {
                if (this.block_show_client_form) {
                    return false;
                }
                var component = this.$root.findComponent(this, 'client-form');
                if (component) {
                    component.editClient(id);
                    $('#client-modal').modal('show');
                }
            },
            getClients: function () {
                this.loading = true;
                this.errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/clients';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.items = response.data.clients;
                    vm.num_items = vm.items.length;
                    if (vm.num_items > 0) {
                        vm.items.forEach((item) => {
                            vm.total_api_calls[item.id] = null;
                        });
                        vm.getAdminKey();
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            primaryColourStyles: function (item) {
                return 'background-color: ' + item.primary_colour + '; color: ' + item.primary_text_colour;
            },
            secondaryColourStyles: function (item) {
                return 'background-color: ' + item.secondary_colour + '; color: ' + item.secondary_text_colour;
            },
            thirdColourStyles: function (item) {
                return 'background-color: ' + item.third_colour + '; color: ' + item.third_text_colour;
            },
            apiCalls: function (itemId) {
                if (!(itemId in this.total_api_calls)) {
                    return null;
                }
                if (this.total_api_calls[itemId] === null) {
                    return null;
                }

                return this.total_api_calls[itemId];
            },
            getAdminKey: function () {
                if (this.admin_key) {
                    this.getApiCalls();
                }
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/admin-key';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.admin_key = response.data;
                    vm.getApiCalls();
                })
                .catch(function (error) {
                    //
                });
            },
            getApiCalls: function (itemId = null) {
                if (!this.admin_key) {
                    return null;
                }
                if (this.num_items == 0) {
                    return null;
                }
                if (!itemId) {
                    if (!this.auto_update_api_calls && (this.apiCalls(this.items[0].id) !== null)) {
                        return null;
                    }
                    this.items.forEach((item) => {
                        this.getApiCalls(item.id);
                    });

                    return null;
                }

                const todaysDate = new Date();
                let month = ('0' + (todaysDate.getMonth() + 1)).slice(-2);
                let day = ('0' + todaysDate.getDate()).slice(-2);
                const today = todaysDate.getFullYear() + '-' + month + '-' + day;

                const currentDate = new Date();
                month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
                const dateFrom = currentDate.getFullYear() + '-' + month + '-01';
                let dateTo = null;

                const lastDayOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                day = ('0' + lastDayOfMonthDate.getDate()).slice(-2);
                const lastDayOfMonth = currentDate.getFullYear() + '-' + month + '-' + day;

                if (lastDayOfMonth < today) {
                    dateTo = lastDayOfMonth;
                } else {
                    dateTo = today;
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + itemId + '/api-calls/admin/' + this.admin_key
                    + '/dr::' + dateFrom + '-' + dateTo;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let totalCalls = 0;
                    response.data.calls.forEach((item) => {
                        totalCalls += parseInt(item.number_of_calls);
                    });
                    vm.total_api_calls[response.data.client_id] = totalCalls;
                    vm.get_api_calls_count++;
                })
                .catch(function (error) {
                    //
                });
            },
            formatStorageSize: function (bytes) {
                if (!bytes) {
                    return 'n/a';
                }
                let formatted = '';
                const multiplier = 1024;
                if (bytes > (multiplier * multiplier * multiplier * 900)) {
                    const tb = (bytes / (multiplier * multiplier * multiplier * multiplier));
                    formatted = tb.toFixed(1).toString() + 'TB';

                    return formatted.replace(/\.0/g, '');
                }
                if (bytes > (multiplier * multiplier * 900)) {
                    const gb = (bytes / (multiplier * multiplier * multiplier));
                    formatted = gb.toFixed(1).toString() + 'GB';

                    return formatted.replace(/\.0/g, '');
                }
                if (bytes > (multiplier * 900)) {
                    const mb = (bytes / (multiplier * multiplier));
                    formatted = mb.toFixed(1).toString() + 'MB';

                    return formatted.replace(/\.0/g, '');
                }
                const kb = Math.ceil(bytes / multiplier);

                return kb + 'KB';
            }
        }
    }
</script>
